import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../Variables';
import moment from 'moment';
import 'moment/locale/hr';
import { Popover, Icon } from 'antd';

const StationWrapper = styled.div`
  padding: 2rem;

  h6 {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 14px;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  p {
    color: ${theme.black};
    font-size: 13px;

    span {
      color: ${theme.gray};
    }

    i {
      color: ${theme.primary};
      margin-left: 0.75rem;
    }
  }

  .btn {
    display: inline-block;
    cursor: pointer;
    font-size: 11px;
    border: 1px solid;
    border-color: ${theme.primary};
    color: #ffffff;
    background-color: ${theme.primary};
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: all 0.15s ease;
    margin-top: 0.25rem;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 0.2rem;

    &:hover {
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.08);
      background-color: ${theme.primaryDarker};
      border-color: ${theme.primaryDarker};
    }
  }
`;

const BackLink = styled.span`
  display: block;
  cursor: pointer;
  font-size: 12px;
  color: rgba(80, 81, 79, 0.5);
  transition: all 0.15s ease;
  margin-bottom: 1.5rem;

  i {
    font-size: 10px;
    color: rgba(80, 81, 79, 0.5);
    margin-left: 0;
    margin-right: 0.25rem;
  }
`;

const Popup = styled.div`
  max-width: 400px;
  margin-top: 1rem;

  p {
    color: ${theme.gray};
    font-size: 12px;

    span {
      color: ${theme.black};
    }
  }
`;

function Station(props) {
  const { station, map, location, selected } = props;
  const dateCreated = station.date_created;
  const momentDate = moment.unix(dateCreated).format('Do MMMM YYYY. HH:mm');
  moment.locale('hr');

  const handleBack = () => {
    map.fitBounds(location);
    map.panToBounds(location);
  };

  return (
    <StationWrapper>
      {selected ? (
        <Link to="/" onClick={handleBack}>
          <BackLink>
            <Icon type="left" />
            Povratak
          </BackLink>
        </Link>
      ) : null}
      <h6>{station.alias}</h6>
      <hr />
      <p>
        Vrijeme mjerenja: <span>{momentDate}h</span>
      </p>
      {station.data.map(item => {
        let legend = (
          <Popup>
            {item.legend.map(level => {
              return (
                <p key={level.level_label}>
                  Od <span>{level.lowest_value}</span> do{' '}
                  <span>{level.highest_value}</span> - {level.level_label}
                </p>
              );
            })}
          </Popup>
        );

        if (item.show_to_user) {
          return (
            <p key={item.label}>
              {item.label}:{' '}
              <span>
                {item.value} {item.measure ? item.measure : null}
              </span>
              {item.legend.length > 0 ? (
                <Popover content={legend} placement="left">
                  <Icon type="info-circle" />
                </Popover>
              ) : null}
            </p>
          );
        }
        return null;
      })}
      <Link to={`/${station.uid}/history`}>
        <span className="btn">Povijest mjerenja</span>
      </Link>
    </StationWrapper>
  );
}

export default Station;

import React from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import { size } from '../Variables';

import Stations from './Stations';
import History from './History';

const SidebarWrapper = styled.div`
  top: 0;
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 3px 1px rgba(60, 64, 67, 0.16);
  height: calc(100% - 2px);
  flex: 0 0 100vw;
  width: 100vw;
  transition: right 0.3s linear;

  @media ${size.medium} {
    flex: 0 0 400px;
    width: auto;
    position: relative;
  }
`;

const drawerOpen = {
  right: '0'
};

const drawerClosed = {
  right: '-100vw'
};


const DrawerWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: scroll;
`;

function Sidebar(props) {
  const { stations, map, location, drawer } = props;

  return (
    <SidebarWrapper style={drawer ? drawerOpen : drawerClosed}>
      <DrawerWrapper>
        <Route
          path="/:station?"
          exact
          render={props => <Stations {...props} stations={stations} map={map} location={location} />}
        />
        <Route
          path="/:station/history"
          exact
          render={props => <History {...props} stations={stations} />}
        />
      </DrawerWrapper>
    </SidebarWrapper>
  );
}

export default Sidebar;

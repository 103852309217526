import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { theme, size } from '../Variables';

import bypathLogo from '../img/bypath-logo.png';
import interregLogo from '../img/interreg-logo.png';
import hunCroLogo from '../img/hun-cro-logo.png';

const FooterWrapper = styled.div`
  position: relative;
  height: 100px;
  width: 100%;
  box-shadow: 0 1px 3px 1px rgba(60, 64, 67, 0.16);
  padding-top: 10px;

  @media ${size.medium} {
    height: 60px;
    padding-top: 0;
  }
`;

const LogosWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  img {
    width: 60px;
    margin: 0 1.5rem;
  }
`;

const ContactWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0;

  @media ${size.medium} {
    justify-content: flex-end;
    padding-right: 2rem;
  }

  p,
  span,
  a {
    font-size: 12px;
    margin: 0;
  }

  p {
    color: ${theme.gray};
  }

  span {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  a {
    color: ${theme.black};

    &:hover {
      color: ${theme.primary};
    }
  }
`;

function Footer() {
  return (
    <FooterWrapper>
      <Row type="flex" justify="center" align="middle">
        <Col sm={{span: 24, offset: 0}} md={{span: 12, offset: 6}}>
          <LogosWrapper>
            <a href="http://bypath.miholjac.eu/en/">
              <img src={bypathLogo} alt="bypathLogo" />
            </a>
            <a href="https://www.interregeurope.eu/">
              <img src={interregLogo} alt="interregLogo" />
            </a>
            <img src={hunCroLogo} alt="hunCroLogo" />
          </LogosWrapper>
        </Col>
        <Col sm={{span: 24}} md={{span: 6}}>
          <ContactWrapper>
            <p>Created by Marrow Labs</p>
            <span>|</span>
            <a href="mailto:greenmap@marrowlabs.com">Kontakt</a>
          </ContactWrapper>
        </Col>
      </Row>
    </FooterWrapper>
  );
}

export default Footer;

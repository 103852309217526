import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

function Map({ options, onMount, className }) {
  const props = { ref: useRef(), className };
  const onLoad = () => {
    const map = new window.google.maps.Map(props.ref.current, options);
    onMount && onMount(map);
  };

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement(`script`);
      script.type = `text/javascript`;
      script.src =
        `https://maps.google.com/maps/api/js?key=` +
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const headScript = document.getElementsByTagName(`script`)[0];
      headScript.parentNode.insertBefore(script, headScript);
      script.addEventListener(`load`, onLoad);
      return () => script.removeEventListener(`load`, onLoad);
    } else onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MapWrapper {...props} />;
}

Map.defaultProps = {
  options: {
    center: { lat: 48, lng: 8 },
    zoom: 5
  }
};

export default Map;

import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { theme } from './Variables';

import Home from './components/Home';

const GlobalStyles = createGlobalStyle`
  html, body, h1, h2, h3, h4, h5, h6, p, div, a {
    @import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,600&subset=latin-ext');
    font-family: 'Work Sans', sans-serif;
  }

  h1, h2, h3, h4, h5, h6, p {
    color: ${theme.black}
  }
`;

function App() {
  return (
    <Router>
      <GlobalStyles />
      <Route path="/" component={Home} />
    </Router>
  );
}

export default App;

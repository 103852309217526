import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../Variables';
import { Icon, Collapse, Pagination, Empty, Popover } from 'antd';
import moment from 'moment';
import 'moment/locale/hr';

import Loading from './Loading';

const HistoryWrapper = styled.div`
  padding: 2rem;

  h6 {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 14px;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  p {
    color: ${theme.black};
    font-size: 13px;

    span {
      color: ${theme.gray};
    }

    i {
      color: ${theme.primary};
      margin-left: 0.75rem;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: ${theme.gray};
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    color: ${theme.primary};
  }
`;

const BackLink = styled.span`
  display: block;
  cursor: pointer;
  font-size: 12px;
  color: rgba(80, 81, 79, 0.5);
  transition: all 0.15s ease;
  margin-bottom: 1.5rem;

  i {
    font-size: 10px;
    color: rgba(80, 81, 79, 0.5);
    margin-left: 0;
    margin-right: 0.25rem;
  }
`;

const { Panel } = Collapse;

const CustomPanel = styled(Panel)`
  background-color: rgba(0, 0, 0, 0.03);
  border: 0;
  border-radius: 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  font-size: 13px;
  margin-bottom: 1rem;
  overflow: hidden;
`;

const CustomPagination = styled(Pagination)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;

  .ant-pagination-item {
    a {
      color: ${theme.gray};
    }

    &.ant-pagination-item-active {
      background-color: ${theme.primary};
      border: 1px solid ${theme.primary};
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

      a {
        color: #ffffff;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    i {
      color: ${theme.primary};
    }

    &.ant-pagination-disabled {
      i {
        color: rgba(0, 0, 0, 0.09);
      }
    }
  }

  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: ${theme.primary};
  }
`;

const Popup = styled.div`
  max-width: 400px;
  margin-top: 1rem;

  p {
    color: ${theme.gray};
    font-size: 12px;

    span {
      color: ${theme.black};
    }
  }
`;

function History(props) {
  const uid = props.match.params.station;
  const station = props.stations.find(obj => {
    return obj.uid === uid;
  });
  const [loaded, setLoaded] = useState(false);
  const [history, setHistory] = useState({});
  const [page, setPage] = useState(1);

  async function fetchData() {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/stations/history/${uid}?page=${page}`,
      {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Api-Key': process.env.REACT_APP_BASE_API_KEY,
          'X-Authorization': process.env.REACT_APP_BASE_AUTHORIZATION_KEY
        })
      }
    );
    response
      .json()
      .then(response => {
        setHistory(response);
        setLoaded(true);
      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePage = targetPage => {
    setPage(targetPage);
    setLoaded(false);
  };

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Icon type="caret-left" />;
    }
    if (type === 'next') {
      return <Icon type="caret-right" />;
    }
    return originalElement;
  };

  return (
    <HistoryWrapper>
      {loaded ? (
        history.data.length > 0 ? (
          <div>
            <Link to={`/${uid}`}>
              <BackLink>
                <Icon type="left" />
                Povratak na stanicu
              </BackLink>
            </Link>
            <h6>{station.alias}</h6>
            <hr />
            <Collapse
              accordion
              bordered={false}
              defaultActiveKey={history.data[0].id}
              expandIcon={({ isActive }) => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}
              style={{ marginBottom: '2.5rem' }}
            >
              {history.data.map(item => {
                const dateCreated = item.date_created;
                const momentDate = moment
                  .unix(dateCreated)
                  .format('Do MMMM YYYY. HH:mm');
                moment.locale('hr');

                return (
                  <CustomPanel key={item.id} header={`${momentDate}h`}>
                    {item.sensor_measurements.map(measurement => {
                      let legend = (
                        <Popup>
                          {measurement.sensor_obj.legend.map(level => {
                            return (
                              <p key={level.level_label}>
                                Od <span>{level.lowest_value}</span> do{' '}
                                <span>{level.highest_value}</span> -{' '}
                                {level.level_label}
                              </p>
                            );
                          })}
                        </Popup>
                      );

                      if (measurement.sensor_obj.show_to_user) {
                        return (
                          <p key={measurement.sensor_obj.label}>
                            {measurement.sensor_obj.label}:{' '}
                            <span>
                              {measurement.value}{' '}
                              {measurement.sensor_obj.measure
                                ? measurement.sensor_obj.measure
                                : null}
                            </span>
                            {measurement.sensor_obj.legend.length > 0 ? (
                              <Popover
                                content={legend}
                                placement="left"
                                arrowPointAtCenter
                              >
                                <Icon type="info-circle" />
                              </Popover>
                            ) : null}
                          </p>
                        );
                      }
                      return null;
                    })}
                  </CustomPanel>
                );
              })}
            </Collapse>
            <CustomPagination
              size="small"
              current={page}
              total={history.total}
              onChange={handlePage}
              showLessItems={true}
              itemRender={itemRender}
            />
          </div>
        ) : (
          <div>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Stanica nije pronađena"
            />
            <Link to={`/`}>
              <BackLink style={{ textAlign: 'center' }}>
                <Icon type="left" />
                Povratak
              </BackLink>
            </Link>
          </div>
        )
      ) : (
        <Loading />
      )}
    </HistoryWrapper>
  );
}

export default History;

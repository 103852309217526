const theme = {
  primary: 'rgb(26, 168, 147)',
  primaryDarker: 'rgb(24, 148, 129)',
  black: 'rgb(80, 81, 79)',
  gray: 'rgba(80, 81, 79, 0.5)'
};

const size = {
  small: `(min-width: 576px)`,
  medium: `(min-width: 768px)`,
  large: `(min-width: 992px)`,
  extraLarge: `(min-width: 1200px)`
};

export {
  theme,
  size
};


import React from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingSpinnerOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spin = keyframes`
  0% { 
    transform: rotate(0deg); 
  }

  100% { 
    transform: rotate(360deg); 
  }
`;

const LoadingSpinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #44ccaf;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 2s linear infinite;
  margin: 0 auto;
`;

function Loading() {
  return (
    <LoadingSpinnerOverlay>
      <LoadingSpinner />
    </LoadingSpinnerOverlay>
  );
}

export default Loading;

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { theme, size } from "../Variables";
import { Icon } from "antd";

import Map from "./Map";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Loading from "./Loading";

const Container = styled.div`
  margin: 0;
  padding: 0;
  height: 100vh;
`;

const Wrapper = styled.div`
  height: calc(100vh - 100px);
  display: block;
  position: relative;

  @media ${size.medium} {
    height: calc(100vh - 60px);
    display: flex;
  }
`;

const DrawerButton = styled.div`
  height: 40px;
  width: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25px;
  right: 10px;
  cursor: pointer;
  z-index: 999;

  i {
    color: ${theme.primary};

    svg {
      transition: transform 0.3s;
    }
  }

  @media ${size.medium} {
    display: none;
  }
`;

const drawerOpen = {
  boxShadow: "none",
};

const drawerClosed = {
  boxShadow: "-1px 1px 3px 1px rgba(60, 64, 67, 0.16)",
};

function Home(props) {
  const [loaded, setLoaded] = useState(false);
  const [stations, setStations] = useState([]);
  const [selectedStation, selectStation] = useState({});
  const [drawer, setDrawer] = useState(true);
  const [map, setMap] = useState({});
  const [location, setLocation] = useState({});

  async function fetchData() {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stations`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Api-Key": process.env.REACT_APP_BASE_API_KEY,
        "X-Authorization": process.env.REACT_APP_BASE_AUTHORIZATION_KEY,
      }),
    });

    response
      .json()
      .then((response) => {
        setStations(response);
        setLoaded(true);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    fetchData();
    updateDrawer();
    window.addEventListener("resize", updateDrawer);
  }, []);

  const updateDrawer = () => {
    if (window.innerWidth > 768) {
      setDrawer(true);
    }
  };

  const handleDrawer = () => {
    setDrawer(!drawer);
  };

  const addMarkers = (stations) => (map) => {
    console.log(stations);
    setMap(map);
    // Init variable for marker boundaries
    const bounds = new window.google.maps.LatLngBounds();

    stations.forEach((station, index) => {
      let position = {};
      let radiusColor;
      const co2Value = station.data.filter((obj) => {
        return obj.name === "eCO2";
      })[0].value;

      if (!station.geo) {
        if (station.uid === "6SB5KbnRFEvzAGXkf1dowCi8VYhNcLMy") {
          position = { lat: 45.764238, lng: 18.165197 };
        } else if (station.uid === "QSNf1e52pTHPXARcnoDIhKvUFqEj4ztM") {
          position = { lat: 45.778152, lng: 18.196788 };
        }
      } else {
        position = { lat: station.geo.lat, lng: station.geo.lon };
      }

      const marker = new window.google.maps.Marker({
        map,
        position: position,
        title: station.alias,
      });

      // Location of each marker (needed for setting zoom level and map center dynamically
      // based on locations of markers)
      const loc = new window.google.maps.LatLng(position.lat, position.lng);
      bounds.extend(loc);
      setLocation(bounds);

      // Set the color of marker radiuses based on data
      if (co2Value < 1000) {
        radiusColor = "#8CD790";
      } else if (co2Value >= 1000 && co2Value < 5000) {
        radiusColor = "#FFDF64";
      } else if (co2Value >= 5000 && co2Value < 40000) {
        radiusColor = "#C1666B";
      } else {
        radiusColor = "#FB3640";
      }

      // Init marker radius
      const circle = new window.google.maps.Circle({
        strokeOpacity: 0.8,
        strokeWeight: 0,
        fillOpacity: 0.5,
        strokeColor: radiusColor,
        fillColor: radiusColor,
        map: map,
        center: marker.position,
        radius: 750,
      });

      // Set the zoom and map center based on marker locations (boundaries)
      map.fitBounds(bounds);
      map.panToBounds(bounds);

      marker.addListener("click", () => {
        selectStation(station.uid);
        props.history.push(`/${station.uid}`);
        map.setZoom(18);
        map.panTo(marker.getPosition());
      });

      map.addListener("click", () => {
        map.fitBounds(bounds);
        map.panToBounds(bounds);
        props.history.push("/");
      });

      circle.addListener("click", () => {
        map.fitBounds(bounds);
        map.panToBounds(bounds);
        props.history.push("/");
      });
    });
  };

  const mapProps = {
    options: {
      disableDoubleClickZoom: true,
      disableDefaultUI: true,
      styles: [
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [
            {
              visibility: "on",
            },
            {
              color: "#e0efef",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry.fill",
          stylers: [
            {
              visibility: "on",
            },
            {
              hue: "#1900ff",
            },
            {
              color: "#c0e8e8",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              lightness: 100,
            },
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              visibility: "on",
            },
            {
              lightness: 700,
            },
          ],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              color: "#7dcdcd",
            },
          ],
        },
      ],
    },
    onMount: addMarkers(stations),
  };

  return loaded ? (
    <Container>
      <Wrapper>
        <Map {...mapProps} />
        <Sidebar
          stations={stations}
          selectedStation={selectedStation}
          drawer={drawer}
          map={map}
          location={location}
        />
        <DrawerButton
          onClick={handleDrawer}
          style={drawer ? drawerOpen : drawerClosed}
        >
          <Icon type="right" rotate={drawer ? 0 : -180} />
        </DrawerButton>
      </Wrapper>
      <Footer />
    </Container>
  ) : (
    <Loading />
  );
}

export default withRouter(Home);

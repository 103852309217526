import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Empty } from 'antd';

import Station from './Station';

const BackLink = styled.span`
  display: block;
  cursor: pointer;
  font-size: 12px;
  color: rgba(80, 81, 79, 0.5);
  transition: all 0.15s ease;
  margin-bottom: 1.5rem;

  i {
    font-size: 10px;
    color: rgba(80, 81, 79, 0.5);
    margin-left: 0;
    margin-right: 0.25rem;
  }
`;

function Stations(props) {
  const { stations, map, location } = props;
  const selectedStation = props.match.params.station;
  const filteredStations = selectedStation
    ? stations.filter(station => {
        return station.uid === selectedStation;
      })
    : stations;

  if (filteredStations.length > 0) {
    return filteredStations.map(station => {
      return <Station key={station.uid} station={station} selected={selectedStation} map={map} location={location} />;
    });
  } else {
    return (
      <div>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Stanica nije pronađena"
        />
        <Link to={`/`}>
          <BackLink style={{ textAlign: 'center' }}>
            <Icon type="left" />
            Povratak
          </BackLink>
        </Link>
      </div>
    );
  }
}

export default Stations;
